export default (pattern) => {
  const metaTags = document.getElementsByTagName('meta');
  const tags = {};

  for (let i = 0; i < metaTags.length; i += 1) {
    if (
      metaTags[i].attributes.name &&
      (metaTags[i].attributes.content?.value ||
        metaTags[i].attributes.itemprop?.value)
    ) {
      const matches = metaTags[i].attributes.name.value.match(pattern);
      if (matches) {
        tags[matches[1]] =
          metaTags[i].attributes.content?.value ||
          metaTags[i].attributes.itemprop.value;
      }
    }
  }

  return tags;
};
