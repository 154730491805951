/* The get-breakpoint-utility is a way of registering listeners on window
 * resizes.
 */

/* Collect a breakpoint from a html pseudo selector, which can be set in CSS */
const getBpFromPseudo = (element, pseudo) => {
  try {
    const breakpoint = window
      .getComputedStyle(document.querySelector(element), `::${pseudo}`)
      .getPropertyValue('content')
      .replace(/["']/g, '');

    // if we get "none", it means content is not set on the pseudo, so we evaluate as false
    return breakpoint !== 'none' ? breakpoint : false;
  } catch (e) {
    // if something irks, return false
    return false;
  }
};

/* get current breakpoint from html::after, html::before or to fallback value "large" */
const getCurrentBreakpoint = () =>
  getBpFromPseudo('html', 'after') ||
  getBpFromPseudo('html', 'before') ||
  'large';

/* In order to not call .getComputedStyle many times for the same window-
 * size, we keep a map of memoizations. They are basic window-width ->
 * breakpoint mappings. */
const memoizations = {};

export default () => {
  /* Get the current window width */
  const currentWindowWidth = window.innerWidth;

  /* And use the memoization if we have one. If we don't have a
   * memoization, we add one using .getComputedStyle. */
  memoizations[currentWindowWidth] =
    memoizations[currentWindowWidth] || getCurrentBreakpoint();

  /* Return the memoization. */
  return memoizations[currentWindowWidth];
};
