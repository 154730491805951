// Helpful map of breakpoints and their starting-width. Used to provide
// meaningful data-sizes-xxx attributes, but must be maintained(!)
export const breakpoints = {
  skyscraperslarge: 1616,
  skyscrapersmall: 1344,
  dd: 1025,
  large: 1024,
  medium: 680,
  small: 0, // so the actual breakpoint is at 480, and 0 is actually base
};

export const extractSizeArray = (sizes = '') =>
  sizes
    .split(/,/)
    .filter((dim) => dim !== 'none') // "none" can be used to explicitly express no sizes for a breakpoint
    .map((dim) => {
      if (dim.indexOf('x') !== -1) {
        // we need to extract a size
        return dim.split(/x/).map((size) => parseInt(size, 10));
      }
      // it's a string name (e.g. fluid)
      return dim;
    });

// Helper-function: Take a size-mapping array and return a plausible
// default size for the ad. It will find the smallest breakpoint that has a
// sizes defined, and return the first of these sizes.
// If no breakpoints have any sizes defined, then 320x180 will be returned
// (but why would you have an ad without any sizes at all?)
export const getDefaultSize = (sizeMapping) => {
  for (let i = sizeMapping.length - 1; i >= 0; i -= 1) {
    if (sizeMapping[i][1] && sizeMapping[i][1][0]) {
      return sizeMapping[i][1][0];
    }
  }

  // None of the breakpoints had any sizes defined. Silly ad...
  return [320, 180];
};

// Helper-function: Takes a ad-unit-element and returns a size-mapping array
// for DFP. It does this by investigating the data-sizes-attributes on the
// element
export default (elem) => {
  const sizeMapping = [];
  let matches;

  for (let i = 0; i < elem.attributes.length; i += 1) {
    // Loop through all of the attributes of the element, and find all those
    // that are the data-sizes-attributes.
    matches = elem.attributes[i].name.match(/^data-sizes(-([a-z]+))?$/);
    if (matches) {
      // Get breakpoint from pattern. We convert "wallpaper" to "dd"
      // in order to avoid backwards incompatibility, since "dd" was
      // previously known as "wallpaper"
      let breakpoint = matches[2];
      if (breakpoint === 'wallpaper') {
        breakpoint = 'dd';
      }

      const size = breakpoint ? breakpoints[breakpoint] : 0;
      const mapping = [[size, 0]];

      // It is a data-sizes attribute
      const { value } = elem.attributes[i];
      if (value) {
        if (!value.match(/((^|,)(([0-9]+x[0-9]+)|([a-z]+)))+$/)) {
          // The value of the attribute must be either a size-definition
          // (e.g. [height1]x[width1],[height2]x[width2],... )
          // or a string (e.g. fluid)
          /* eslint-disable no-console */
          if (console && console.warn) {
            console.warn(
              'Ad-size',
              value,
              'invalid. Ad-id:',
              elem.attributes['data-ad'],
            );
          }
          /* eslint-enable no-console */
          continue; // eslint-disable-line no-continue
        }

        if (breakpoint && breakpoints[breakpoint] === undefined) {
          // The attribute mentioned a breakpoint size that didn't exist..
          /* eslint-disable no-console */
          if (console && console.warn) {
            console.warn(
              'Ad-size defined for a unknown breakpoint:',
              breakpoint,
              '. Ad-id:',
              elem.attributes['data-id'],
            );
          }
          /* eslint-enable no-console */
          continue; // eslint-disable-line no-continue
        }
        mapping.push(extractSizeArray(value));
      } else {
        mapping.push([]);
      }

      sizeMapping.push(mapping);
    }
  }

  // We sort the size-mappings such that the biggest breakpoints are always
  // first...
  sizeMapping.sort((a, b) => b[0][0] - a[0][0]);
  return sizeMapping;
};
