import onBreakpoint from '../utils/on-breakpoint';

export default (googletag) => {
  // Firstly, we need to refresh the ads whenever the breakpoint changes, in
  // case the ads needs to be a different size..
  onBreakpoint(() => {
    googletag.cmd.push(() => {
      const body = window.document.getElementsByTagName('body')[0];
      body.className = body.className.replace(/(^| )newtakeover($| )/, '');

      const previousWallpaperStyle = window.document.getElementById(
        'wallpaper-style',
      );
      if (previousWallpaperStyle) {
        previousWallpaperStyle.parentNode.removeChild(previousWallpaperStyle);
      }
      const previousTakeoverStyles = window.document.getElementsByName(
        'takeover-style',
      );
      if (previousTakeoverStyles) {
        Array.prototype.forEach.call(previousTakeoverStyles, (el) => {
          el.parentNode.removeChild(el);
        });
      }
    });
  }, true);
};
