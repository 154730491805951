export function pbjsHandleSync() {
  const highestBids = window.pbjs.getHighestCpmBids();
  const adUnitArray = window.pbjs.adUnits.map((unit) => unit.code);
  // http://prebid.org/dev-docs/publisher-api-reference.html#module_pbjs.setTargetingForGPTAsync
  window.pbjs.setTargetingForGPTAsync(
    adUnitArray,
    (googleAdSlot) => (adUnitCode) => {
      const googleAdPath = googleAdSlot.getAdUnitPath();
      // get eg. "Wallpaper" from /19784602/tv2dk-web/Nyheder/Nyheder_Underside/Nyheder_Underside_Topbanner_1--Wallpaper
      const matches = adUnitCode.match(/--([\w]+)$/);

      // Is the googleAdSlot the same as the adUnitCode (disregarding twin unit postfix)?
      const isTheSame =
        adUnitCode.toLowerCase().indexOf(googleAdPath.toLowerCase()) !== -1;
      if (!isTheSame) {
        return false;
      }

      // Twin unit type or false
      const twinUnitType =
        matches && matches[1] ? matches[1].toLowerCase() : false;

      // Is this unit the one with the highest cpm?
      // Either there's no twin or no original or this is the twin and its cpm is
      // higher than the original
      let isHighestCPM = true;
      if (twinUnitType) {
        const bidForOriginal = highestBids.filter(
          (bid) => bid.adUnitCode === googleAdPath,
        )[0];
        const bidForTwin = highestBids.filter(
          (bid) => bid.adUnitCode === adUnitCode,
        )[0];

        isHighestCPM =
          (bidForTwin && !bidForOriginal) ||
          (bidForTwin && bidForTwin.cpm >= bidForOriginal.cpm);
      }

      // if it has the highest cpm, set the banner type
      if (isHighestCPM) {
        googleAdSlot.setTargeting('hb_banner_type', twinUnitType || 'standard');
      }

      // if it is the highest cpm
      return isHighestCPM;
    },
  );
}
