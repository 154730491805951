import { ucfirst } from './ucfirst';

function getMsupplyKeywords(tracking = {}) {
  const keywords = {
    TV2_DK_L1_Gender: 'neutral',
    TV2_DK_L1_Domain: window.location.hostname,
  };

  const { event, tema, program, sport, section, site } = tracking;
  // In case section is set but site is not,
  // specify a default subcategory key
  let subCategoryKey = 'TV2_DK_L2_subcategory_Default';
  const sectionList = [event, tema, program, sport, section]
    .filter(Boolean) // Use only defined values
    .map((n) => ucfirst(n)) // uppercase the first letter
    .join(', '); // convert to list

  if (site) {
    const siteName = ucfirst(site);
    keywords.TV2_DK_L1_Category = siteName;
    subCategoryKey = `TV2_DK_L2_subcategory_${siteName}`;
  }

  if (sectionList) {
    keywords[subCategoryKey] = sectionList;
  }

  return keywords;
}

export { getMsupplyKeywords };
