import getBreakpoint from './get-breakpoint';
import onResize from './on-resize';

/* The on-breakpoint is a utility that lets you register listeners for when the
 * current breakpoint of the site changes. It has two modes of operation (where
 * one is actually just shorthand for the other).
 * You can either simply give it a function, which will then be called with a
 * single argument on breakpoint-changes: The newly active breakpoint.
 * Alternatively you can give it a hash-map of breakpoints -> functions. When
 * the breakpoint changes, the relevant function from the hash-map will be
 * called. It's important to note that the breakpoints cascade similarly to how
 * the styles themselves do. So if you have a function defined for the `medium`
 * breakpoint, but not for any bigger breakpoints, then the function will get
 * called when the breakpoint changes into either `medium` or any of the bigger
 * breakpoints. */
const ordering = ['base', 'small', 'medium', 'large'];
let registeredTasks = [];
let previousBreakpoint = getBreakpoint();

/* Run a single task-object for a given breakpoint */
// eslint-disable-next-line consistent-return
const runTask = (breakpoint, tasks) => {
  /* Go from the current breakpoint, and work our way down until a breakpoint
   * with a function registered is found */
  for (let i = ordering.indexOf(breakpoint); i >= 0; i -= 1) {
    if (tasks[ordering[i]]) {
      /* The breakpoint `ordering[i]` is defined, so call it */
      try {
        return tasks[ordering[i]](breakpoint);
      } catch (e) {
        // The task failed - so we will remove this specific task from running
        // again in the future
        /* eslint-disable no-console */
        if (console && console.warn && console.error) {
          console.warn(
            `A on-breakpoint handler failed when switching into ${breakpoint}. It will be disabled!`,
          );
          console.error(e);
        }
        /* eslint-enable no-console */
        delete tasks[ordering[i]]; // eslint-disable-line no-param-reassign
      }
    }
  }
};

/* Register a single resize-handler that simply runs all registered tasks
 * whenever the breakpoint changes */
onResize(() => {
  const currentBreakpoint = getBreakpoint();
  if (currentBreakpoint !== previousBreakpoint) {
    /* The breakpoint changed, so run all tasks */
    registeredTasks.forEach(runTask.bind(undefined, currentBreakpoint));

    // Run through the lists of tasks and see if any of the task-sets can be
    // pruned...
    registeredTasks = registeredTasks.reduce((prunedList, registeredTask) => {
      if (Object.keys(registeredTask).length > 0) {
        prunedList.push(registeredTask);
        /* eslint-disable no-console */
      } else if (console && console.warn) {
        console.warn(
          'A on-breakpoint task-set have no active tasks. It will be pruned',
        );
      }
      /* eslint-enable no-console */

      return prunedList;
    }, []);

    /* And set the current breakpoint to be the last known one */
    previousBreakpoint = currentBreakpoint;
  }
});

/* The two public functions, bind and unbind.
 * Bind takes a breakpoint->function hash-map and registeres it. Unless true
 * is provided as a second argument, the task is also run immediately */
const bind = (tasks, noImmediateCall) => {
  if (tasks instanceof Function) {
    /* We were given a function instead of a hash-map - which means we should
     * call the function on every breakpoint changes. Because `base` is the
     * smallest breakpoint, and we cascade upwards, this is the same as a
     * hash-map with only `base` defined to the provided function, so we
     * update the tasks-variable to be such a hash-map */
    tasks = { base: tasks }; // eslint-disable-line no-param-reassign
  }

  /* Add the hash-map to the list of registed tasks */
  registeredTasks.push(tasks);

  if (!noImmediateCall) {
    /* And since `true` wasn't passed as the second argument, we also run the
     * task immediately */
    runTask(getBreakpoint(), tasks);
  }

  /* Return the task, so people can store a reference to it, for use in later
   * unbinding */
  return tasks;
};

/* Unbind a task-definition. Simply try and find the hash-map in the list of
 * registed tasks and then remove it */
const unbind = (callback) => {
  const i = registeredTasks.indexOf(callback);
  if (i !== -1) {
    /* We found the hash-map in the list */
    registeredTasks.splice(i, 1);
    return true;
  }
  /* The hash-map isn't in the list of registered tasks */
  return false;
};

/* We expose just `bind` as the module, so you can bind using just
 * `require("app/utils/on-breakpoint")(fn)`. But since we want people to be
 * able to unbind as well, we store `bind` and `unbind` as extra properties on
 * the `bind` function */
bind.bind = bind;
bind.unbind = unbind;

/* We also expose the different breakpoints, in case someone wants to use it
 * for building up task-objects */
bind.breakpoints = ordering;

/* And then export the `bind` function as the contents of the module */
export default bind;
