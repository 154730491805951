import { getParentWildcardDomain } from '../../utils/getParentDomain';

const advertisementIdKey = 'tv2PBJSSessionKey';
const advertisementTraceId = 't2aid';

// https://gist.github.com/gordonbrander/2230317?permalink_comment_id=3752738#gistcomment-3752738
function generateId() {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = '';
  for (let i = 0; i < array.length; i += 1) {
    str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
  }
  return str;
}

function loadAdvertisementId() {
  return new Promise((resolve) => {
    window.tv2messages.onceWithTimeout('traceIds', 8000, (traceIds) => {
      const fromAuth0 = traceIds && traceIds[advertisementTraceId];
      const fromCookie = window.tv2.utils.getCookie(advertisementIdKey);
      const fromSession = window.sessionStorage.getItem(advertisementIdKey);
      resolve(fromAuth0 || fromCookie || fromSession || null);
    });
  });
}

function setAdvertisementId(advertisementId, consent) {
  if (consent) {
    window.tv2.utils.setCookie(
      advertisementIdKey,
      advertisementId,
      '30 days',
      getParentWildcardDomain(window.location.hostname),
    );
  }
  window.sessionStorage.setItem(advertisementIdKey, advertisementId);
}

export async function getAdvertisementId() {
  let advertisementId = await loadAdvertisementId();
  return new Promise((resolve) => {
    const log = window.tv2.utils.setupLog('🆔 Advertiser ID');

    window.tv2messages.onceWithTimeout('hasConsent', 8000, (consent) => {
      if (!advertisementId) {
        log.info('Not found, creating new ID.');
        advertisementId = generateId();
      }

      setAdvertisementId(advertisementId, consent);

      log.info(`Using ${advertisementId}`);
      resolve(advertisementId);
    });
  });
}
