const breakpoints = {
  skyscrapers: 1350,
  dd: 1025,
  large: 1024,
  medium: 680,
  small: 0, // so the actual breakpoint is at 480, and 0 is actually base
};

const log = window.tv2.utils.setupLog();

/* Collect a breakpoint from a html pseudo selector, which can be set in CSS */
export function getBpFromPseudo(element, pseudo) {
  try {
    const breakpoint =
      window
        .getComputedStyle(document.querySelector(element), `::${pseudo}`)
        .getPropertyValue('content')
        .replace(/["']/g, '') || null;

    // if we get "none", it means content is not set on the pseudo, so we evaluate as false
    return breakpoint !== 'none' ? breakpoint : null;
  } catch (e) {
    // if something irks, return false
    log.error('Unable to get breakpoint from pseudo', { element, pseudo });
    return null;
  }
}

/* Helper: get current breakpoint */
export function getBreakpoint() {
  // Attempt to resolve from psuedo element
  const fromPseudo =
    getBpFromPseudo('html', 'after') || getBpFromPseudo('html', 'before');
  if (fromPseudo) {
    return fromPseudo;
  }

  // Attempt to resolve from window width
  if (window) {
    const fromWindow = Object.keys(breakpoints).filter(
      (key) => breakpoints[key] <= window.innerWidth,
    );
    if (fromWindow.length) {
      return fromWindow[0];
    }
  }

  // send an error and default back to large
  log.error('Unable to resolve breakpoint', {
    breakpoints,
    width: (window && window.innerWidth) || 'undefined',
  });
  return 'large';
}
