/* eslint-disable no-undef */
import { ucfirst } from './ucfirst';

// Utility function: From a string-id of a ad-unit-element, generate the
// hierarchical path used by the sales dept in DFP.
// It will take a string like foo-bar-baz, and then return ['Foo',
// 'Foo_Bar', 'Foo_Bar_Baz']. It has a special-case for ids ending in
// `-[number]`, where the number won't get a level by itself, but is
// instead attached to the last level, e.g. foo-bar-1 will be ['Foo',
// 'Foo_Bar_1']..
export function adPath(
  id,
  adPathPrefix = undefined,
  adPositionPrefix = undefined,
) {
  if (!adPathPrefix || !adPositionPrefix) {
    const tv2 = window.tv2 || {};
    tv2.ads = tv2.ads || {};
    // eslint-disable-next-line no-param-reassign
    adPathPrefix = adPathPrefix || tv2.ads.adPathPrefix || [];
    // eslint-disable-next-line no-param-reassign
    adPositionPrefix = adPositionPrefix || tv2.ads.adPositionPrefix || '';
  }

  // Check if the position prefix is empty,
  // meaning the new format is not implemented, fallback to old
  let position;
  if (adPositionPrefix === '') {
    position = id;
  } else {
    position = `${adPositionPrefix}-${id}`;
  }

  const parts = position.replace(/-[0-9]+$/, '').split(/-/);
  const mappings = adPathPrefix.slice();
  let prefix = '';
  const matches = position.match(/-([0-9]+)$/);

  if (matches) {
    parts[parts.length - 1] = `${parts[parts.length - 1]}_${matches[1]}`;
  }

  for (let i = 0; i < parts.length; i += 1) {
    mappings.push(prefix + ucfirst(parts[i]));
    prefix = `${prefix}${ucfirst(parts[i])}_`;
  }

  return mappings;
}
