/* eslint-disable no-use-before-define */
// Time-based refreshing of ads
const getPathsFromSlots = (slots) => slots.map((slot) => slot.getAdUnitPath());
export default function setupRefresh(slots, log) {
  // Setup Configuration
  const slotsToRefresh = ['Skyscraper', 'Underside_Rectangle_3']; // Ad Slot substring
  const refreshInterval = 30; // Interval between refreshes, in seconds
  const dontRefreshTheseBidders = ['omg', 'omg_adnami'];

  const whitelistedAdSlots = Object.values(slots).filter((slot) => {
    const adUnitPath = slot.getAdUnitPath();

    const whitelist = [
      // AdSlot has to be in "slotsToRefresh" array
      !!slotsToRefresh.find((adSlotSubstring) =>
        adUnitPath.includes(adSlotSubstring),
      ),
      // We don't want to refresh skyscraper if we currently have a midscroll
      !(
        adUnitPath.includes('Skyscraper') &&
        document.querySelector('.adnm-html-desktop-interscroll-frame')
      ),
      // Video ad slots are for IMA only
      !adUnitPath.includes('Video'),
    ];
    return !whitelist.includes(false);
  });

  // Configuration END

  // These are ad slots that are whitelisted and are actually loaded
  // They might not load because of breakpoint or are excluded on current decider ad
  const adSlotsToRefresh = [];

  let hasInitialized = false;
  window.googletag.pubads().addEventListener('slotOnload', (event) => {
    const whitelistIndex = whitelistedAdSlots.indexOf(event.slot);
    if (whitelistIndex !== -1) {
      whitelistedAdSlots.splice(whitelistIndex, 1);
      adSlotsToRefresh.push(event.slot);
      if (!hasInitialized) {
        hasInitialized = true;
        setTimeout(initializeRefresh, 5000);
      }
    }
  });

  function initializeRefresh() {
    const adPathsToRefresh = getPathsFromSlots(adSlotsToRefresh);
    log.info(
      'Initializing refresh interval for these adSlots',
      adPathsToRefresh,
    );
    // We want to remove bidders from dontRefreshTheseBidders from bid list
    const allPrebidAdUnits = window.pbjs.adUnits;
    allPrebidAdUnits.forEach((prebidAdUnit) => {
      if (adPathsToRefresh.includes(prebidAdUnit.code)) {
        // eslint-disable-next-line no-param-reassign
        prebidAdUnit.bids = prebidAdUnit.bids.filter(
          (bid) => !dontRefreshTheseBidders.includes(bid.bidder),
        );
      }
    });

    setTimeout(refreshAds, refreshInterval * 1000 - 5000);
  }

  let refreshCount = 0;
  const refreshAds = function refreshAds() {
    refreshCount += 1;
    const adUnitCodes = getPathsFromSlots(adSlotsToRefresh);
    log.info('Refreshing ads', { adUnitCodes });

    adSlotsToRefresh.forEach((slot) => {
      slot.setTargeting('refreshed', refreshCount);
    });

    function actualRefresh() {
      window.removeEventListener('focus', actualRefresh);
      window.pbjs.que.push(() => {
        window.pbjs.requestBids({
          adUnitCodes,
          bidsBackHandler() {
            window.pbjs.setTargetingForGPTAsync(adUnitCodes);
            window.googletag.pubads().refresh(adSlotsToRefresh);

            if (refreshCount <= 5) {
              setTimeout(refreshAds, refreshInterval * 1000);
            }
          },
        });
      });
    }

    // We make sure the tab has focus
    if (document.hasFocus()) {
      actualRefresh();
    } else {
      log.info('Waiting for window to gain focus before refresh');
      window.addEventListener('focus', actualRefresh);
    }
  };
}
