/* The resize-utility is a way of registering listeners on window resizes.
 * We only need this functionality on modern browsers, so for older browsers we
 * instead define a simple no-op function, to avoid failures
 *
 * Usage:
 * Bind a new function to be notified after resize ends
 *
 *     require("app/utils/resize")(function(event, newSize) { ... });
 *
 * Unbind a function, so that it is no longer notified
 *
 *     require("app/utils/resize").unbind(previouslyBoundFunction);
 *
 */

let windowWidth = window.innerWidth;
let resizeTimer = null;
const listeners = [];

// This function will be triggered when the window has changed width. It
// is de-bounched, so it will only be triggered when the window haven't
// changed its width for 0.3 seconds
const windowChangedWidth = (event, newWidth) => {
  // Simply loop through all of the registered listeners and call them
  listeners.forEach((listener) => {
    listener(event, newWidth);
  });
};

// Bind a single shared resize-handler
window.addEventListener('resize', (event) => {
  // Prevent executing when height doesn't change
  if (windowWidth !== window.innerWidth) {
    if (resizeTimer) {
      // Reset the timer if we have one
      clearTimeout(resizeTimer);
    }
    // Update the last known width of the window
    windowWidth = window.innerWidth;
    // And register a (new) timer to notify of window-changes in 0.3 of a
    // second
    resizeTimer = setTimeout(
      windowChangedWidth.bind(undefined, event, windowWidth),
      300,
    );
  }
});

/* The two public functions, bind and unbind.
 * Bind simply takes a function which will be called after each resize */
const bind = (callback) => {
  listeners.push(callback);
  return callback;
};

/* Unbind removes a function from the list of functions that will be
 * called on resize */
const unbind = (callback) => {
  const i = listeners.indexOf(callback);
  if (i !== -1) {
    listeners.splice(i, 1);
    return true;
  }
  return false;
};

/* We expose just `bind` as the module, so you can bind using just
 * `require("app/utils/resize")(fn)`. But since we want people to be able
 * to unbind as well, we store `bind` and `unbind` as extra properties on
 * the `bind` function */
bind.bind = bind;
bind.unbind = unbind;

/* And then return the `bind` function as the contents of the module */
export default bind;
