/* Some internal state-keeping: Is the DOM ready, and whos waiting for it */
let loaded = document.readyState !== 'loading';
const queue = [];

/* Callback function for when the DOM is ready */
export const ready = () => {
  loaded = true; /* Set the DOM as loaded */
  for (let i = 0; i < queue.length; i += 1) {
    /* Loop through all of the people waiting, and let them know its ready.
     * Because of >=IE8, we cant use queue.map() */
    queue[i]();
  }
};

if ('addEventListener' in document) {
  /* We have a nice modern browser, so lets use DOMContentLoaded! */
  document.addEventListener('DOMContentLoaded', ready);
} else if ('onreadystatechange' in document) {
  /* We have IE8, so lets use onReadyStateChange instead */
  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      ready();
    }
  };
} else {
  /* For <=IE7, we can't use anything built-in for DOM-ready. Some people
   * rely on a scroll-trick to emulate DOM-ready, but since we don't really
   * care about the performance of old browsers, we fall back to simply using
   * onload instead */
  document.onload = ready;
}

/* Now with our event-listners bound, we can export the module */
export default (listener) => {
  /* Simply takes a function, and calls it on DOM-ready */
  if (loaded) {
    /* DOM is already there, so call immediately */
    listener();
  } else {
    /* Not there yet, so put in the queue */
    queue.push(listener);
  }
};
