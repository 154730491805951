// Default config rules for the tv2 ads script
const defaultConfig = {
  adPathPrefix: [],
  adPositionPrefix: '',
  legacy: false,
  lazyLoad: {
    fetchMarginPercent: 200,
    renderMarginPercent: 150,
    mobileScaling: 2,
    pageTypes: ['landingpage', 'frontpage', 'liveblog', 'newsfeed'],
  },
};

// Extracting config from the window tv2 object
const tv2 = window.tv2 || {};
const { ads: tv2Ads } = tv2;

// And exporting a config object
export default {
  ...defaultConfig,
  ...tv2Ads,
};
